
let listItemIndex = 0;

module.exports = {
  navigateDropdown
};
/**
 * Adobe accessibility reference:
 * https://accessibility.corp.adobe.com/docs/interaction_design/keyboard_accessibility/#key-commands
 *
 * Navigate a dropdown/popover menu's list items using Up and Down arrow keys instead of Tab
 *
 * The list's popover open & close should be handled separately by the list's main controller
 * The list's items within the tab order should be handled separately by the list's main controller
 *
 * Example in locale switcher controller methods: isButtonDisabled() and navigateLocale(event)
 *
 * @param {*} event
 * @param menuButton - a HTML element that controls whether the dropdown is open or closed
 * @param list - an HTML list of options in the popover menu
 * @return nothing
 */
function navigateDropdown(event, menuButton, list) {
  if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
    event.preventDefault();
  }

  const listItems = list.children;

  if (event.key === 'ArrowDown') {
    listItemIndex = listItemIndex < listItems.length - 1 ? listItemIndex + 1 : 0;
  } else if (event.key === 'ArrowUp') {
    listItemIndex = listItemIndex === 0 ? listItems.length - 1 : listItemIndex - 1;
  }

  listItems[listItemIndex].focus();
}
