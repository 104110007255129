const PostLoginActionProcessor = require('../util/post_login_actions/post_login_action_processor.js').default;
const UrlService = require('../util/url_service.js').default;

// User agents that contain these strings are assumed to be touch devices.
var TOUCH_DEVICE_USER_AGENTS = [
  'Android',
  'BlackBerry',
  'IEMobile',
  'Opera Mini',
  'iPad',
  'iPhone',
  'iPod',
  'webOS'
];

/**
 * A method that the Neue app will run at load time.
 * @ngInject
 */
function Run($rootElement, $window, DataService, FirstMileService, FreezeframeService, NewrelicService, SuspendedAccountService) {
  DataService.loadDataFromTemplate($rootElement);
  FirstMileService.handlePageLoad();
  FreezeframeService.init();
  NewrelicService.init();
  PostLoginActionProcessor.handlePageLoad();
  SuspendedAccountService.handlePageLoad();

  _detectTouchDevice();

  // https://git.corp.adobe.com/typekit/typekit/issues/5726
  UrlService.removeSearchParam('code');

  /**
   * If the device looks like a touch device, then add an extra class for touch devices.
   *
   * NOTE: exact device detection isn't available yet, so this sniffs out a
   * couple properties that indicate that the device is a touch device. If a
   * device is a touch device, but it does match one of these detection methods,
   * the class won't get added.
   */
  function _detectTouchDevice() {
    if (_deviceSupportsTouchEvents() || _deviceHasTouchUserAgent()) {
      $rootElement.addClass('touch-device');
    }
  }

  /**
   * Is this a device that supports touch events?
   * Check if the device supports the touch document APIs. Touch devices that don't expose the
   * touch APIs will hopefully get caught by the other methods of detection.
   * @returns {Boolean}
   */
  function _deviceSupportsTouchEvents() {
    return 'ontouchstart' in $window ||
           ($window.DocumentTouch && $window.document instanceof $window.DocumentTouch);
  }

  /**
   * Does the current device have a touch device user agent?
   *
   * This will miss touch devices that don't use any of the matching user
   * agents, but hopefully those devices will be caught by the other methods of
   * detection.
   *
   * @returns {Boolean}
   */
  function _deviceHasTouchUserAgent() {
    return new RegExp(TOUCH_DEVICE_USER_AGENTS.join('|'), 'i').test($window.navigator.userAgent);
  }
}


module.exports = Run;
